import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FaFolder, FaFolderOpen } from 'react-icons/fa';
import { jwtDecode } from 'jwt-decode';

const Notes = () => {
    const [notes, setNotes] = useState([]);
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [expandedSubcategory, setExpandedSubcategory] = useState({});
    const navigate = useNavigate();

    const isTokenExpired = (token1) => {
        const decodedToken = jwtDecode(token1);
        const currentTime = Date.now() / 1000; // Current time in seconds
        return decodedToken.exp < currentTime;
    };

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                const token1 = localStorage.getItem('token1');
                if (isTokenExpired(token1)) {
                    toast.error('Session expired. Please log in again.');
                    navigate('/signin');
                    return;
                }
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/student/notes`, {
                    headers: {
                        Authorization: token1,
                    },
                });
                const transformedData = transformNotesData(response.data);
                setNotes(transformedData);
            } catch (err) {
                toast.error('Failed to fetch notes');
                if (err.response.status === 401) {
                    navigate('/signin');
                }
            }
        };

        fetchNotes();
    }, [navigate]);

    const transformNotesData = (data) => {
        const transformed = data.reduce((acc, note) => {
            let category = acc.find(cat => cat.category === note.category);
            if (!category) {
                category = { category: note.category, subcategories: [] };
                acc.push(category);
            }

            let subcategory = category.subcategories.find(sub => sub.name === note.subcategory);
            if (!subcategory) {
                subcategory = { name: note.subcategory, files: [] };
                category.subcategories.push(subcategory);
            }

            subcategory.files.push({
                id: note.id,
                document_name: `${note.document_name}.pdf`,
                file_path: `/uploads/${note.file_path}`,
                upload_date: note.upload_date,
            });

            return acc;
        }, []);

        return transformed;
    };

    const toggleCategory = (category) => {
        setExpandedCategory(expandedCategory === category ? null : category);
    };

    const toggleSubcategory = (subcategory) => {
        setExpandedSubcategory(expandedSubcategory === subcategory ? null : subcategory);
    };

    return (
        <div className="space-y-8">
            {notes.map((category) => (
                <div className='bg-gray-200 border border-black m-2 md:mx-20 pt-2 rounded-lg text-center' key={category.category}>
                    <h3 className="text-xl gradient-text font-semibold mb-2 cursor-pointer"
                        onClick={() => toggleCategory(category.category)}>{category.category}</h3>
                    {expandedCategory === category.category && (
                        <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 border-t border-black bg-gray-50 rounded-b-lg py-2 px-6'>
                            {category.subcategories.map((subcategory) => (
                                <div key={subcategory.name} className='min-w-48'>
                                    <h4 className="text-md font-medium max-w-32 pb-1 flex items-center cursor-pointer"
                                        onClick={() => toggleSubcategory(subcategory.name)}>
                                        {expandedSubcategory === subcategory.name ? <FaFolderOpen className='mr-2 text-xl text-yellow-300' /> : <FaFolder className='text-xl mr-2 text-yellow-300' />}
                                        {subcategory.name}
                                    </h4>
                                    {expandedSubcategory === subcategory.name && (
                                        <div className="flex flex-col gap-4 max-w-52">
                                            {subcategory.files.map((file) => (
                                                <div key={file.id} className="flex bg-white p-1 shadow rounded">
                                                    <p className="mr-2">{file.document_name}</p>
                                                    <a
                                                        href={file.file_path}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-500 underline"
                                                    >
                                                        View
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
            <ToastContainer />
        </div>
    );
};

export default Notes;
