import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from 'jwt-decode';

const NotesTab = () => {
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [documentName, setDocumentName] = useState('');
    const [document, setDocument] = useState(null);
    const [documents, setDocuments] = useState([]);
    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    const isTokenExpired = (token) => {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        return decodedToken.exp < currentTime;
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    const fetchDocuments = async () => {
        try {
            const token = localStorage.getItem('token');
            if (isTokenExpired(token)) {
                toast.error('Session expired. Please log in again.');
                navigate('/admin');
                return;
            }
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/documents`, {
                headers: {
                    Authorization: token,
                },
            });
            setDocuments(response.data);
        } catch (err) {
            toast.error('Failed to fetch documents');
            if (err.response.status === 401) {
                navigate('/admin');
            }
        }
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('category', category);
        formData.append('subcategory', subcategory);
        formData.append('documentName', documentName);
        formData.append('document', document);

        try {
            const token = localStorage.getItem('token');
            if (isTokenExpired(token)) {
                toast.error('Session expired. Please log in again.');
                navigate('/admin');
                return;
            }
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/upload`, formData, {
                headers: {
                    Authorization: token,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.message) {
                toast.success('Document uploaded successfully');
                setDocuments([...documents, response.data.document]);
                // Reset form fields
                setCategory('');
                setSubcategory('');
                setDocumentName('');
                setDocument(null);
                fileInputRef.current.value = '';
            } else {
                toast.error('Failed to upload document');
            }
        } catch (err) {
            toast.error('Failed to upload document');
            if (err.response.status === 401) {
                navigate('/admin');
            }
        }
    };

    const handleRemoveDocument = async (documentId) => {
        try {
            const token = localStorage.getItem('token');
            if (isTokenExpired(token)) {
                toast.error('Session expired. Please log in again.');
                navigate('/admin');
                return;
            }
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/admin/delete-document/${documentId}`, {
                headers: {
                    Authorization: token,
                },
            });
            if (response.data.message) {
                toast.success(response.data.message);
                fetchDocuments();
            }
        } catch (err) {
            toast.error('Failed to remove document');
            if (err.response.status === 401) {
                navigate('/admin');
            }
        }
    };

    return (
        <div className="p-2">
            <form onSubmit={handleUpload} className="space-y-4 text-center border-2">
                <h2 className="text-xl font-semibold bg-blue-100 p-2 mb-4">Upload New Document</h2>
                <div className="flex flex-col md:flex-row m-2 space-y-4 md:space-y-0 md:space-x-4">
                    <div className="flex flex-row items-center md:w-1/3">
                        <label className="font-medium mb-1">Category</label>
                        <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className="p-2 mx-2 w-full border border-gray-300 rounded"
                            required
                        >
                            <option value="">Select Category</option>
                            <option value="Lecture notes">Lecture notes</option>
                            <option value="Daily reading">Daily reading</option>
                            <option value="Joint specific reading">Joint specific reading</option>
                        </select>
                    </div>
                    <div className='flex flex-row items-center md:w-1/3'>
                        <label className="font-medium mb-1">Subcategory:</label>
                        <input
                            type="text"
                            value={subcategory}
                            onChange={(e) => setSubcategory(e.target.value)}
                            className="p-2 mx-2 w-full border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="flex flex-row items-center md:w-1/3">
                        <label className="font-medium mb-1">Document Name:</label>
                        <input
                            type="text"
                            value={documentName}
                            onChange={(e) => setDocumentName(e.target.value)}
                            className="p-2 mx-2 w-full border border-gray-300 rounded"
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row m-2 items-center space-y-2 md:space-y-0 md:space-x-2">
                    <label className="font-medium mb-1">Upload Document</label>
                    <input
                        type="file"
                        onChange={(e) => setDocument(e.target.files[0])}
                        className="p-2 max-w-64 overflow-hidden border border-gray-300 rounded"
                        required
                        ref={fileInputRef}
                    />
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                        Upload
                    </button>
                </div>
            </form>

            <h2 className="text-xl text-center bg-blue-100 p-2 font-semibold my-4">Uploaded Documents</h2>
            <div className="overflow-x-auto">
                <table className="w-full text-center bg-white">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Category</th>
                            <th className="py-2 px-4 border-b">Subcategory</th>
                            <th className="py-2 px-4 border-b">Document Name</th>
                            <th className="py-2 px-4 border-b">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documents.map((doc) => (
                            <tr key={doc.id}>
                                <td className="py-2 px-4 border-b">{doc.category}</td>
                                <td className="py-2 px-4 border-b">{doc.subcategory}</td>
                                <td className="py-2 px-4 border-b">{doc.document_name}</td>
                                <td className="py-2 px-4 border-b">
                                    <button
                                        className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                                        onClick={() => handleRemoveDocument(doc.id)}
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ToastContainer />
        </div>
    );
};

export default NotesTab;