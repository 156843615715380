import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';

const RegisterForm = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const navigate = useNavigate();

    const handleRegisterSubmit = async (event) => {
        event.preventDefault();
        if (!firstName || !lastName || !email || !mobileNumber || !password || !confirmPassword) {
            toast.error('Please fill in all the fields.', { position: "top-right" });
            return;
        }
        if (password !== confirmPassword) {
            toast.error('Passwords do not match.', { position: "top-right" });
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/register`, { firstName, lastName, email, mobileNumber, password });
            toast.success('Registration successful! Redirecting to payment page...', { position: "top-right" });
            setTimeout(() => {
                navigate('/payment');
            }, 3000);
        } catch (e) {
            console.error(e);
            toast.error('Registration failed. Please check your details and try again.', { position: "top-right" });
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="min-h-[75vh] bg-gray-100 flex flex-col mt-24 pt-2 items-center justify-center px-4">
                <div className="w-full max-w-xl bg-white p-4 md:p-8 rounded shadow-md m-4">
                    <h3 className="text-3xl text-center font-bold mb-6">Sign Up</h3>
                    <form className="flex flex-col" onSubmit={handleRegisterSubmit}>
                        <div className="flex flex-row gap-4 md:gap-6 w-full">
                            <div className="mb-4 w-full">
                                <label className="block text-gray-700 text-sm font-bold mb-2">First Name</label>
                                <input
                                    type="text"
                                    className="shadow appearance-none border rounded w-full py-1 px-1 text-gray-700"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </div>
                            <div className="mb-4 w-full">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Last Name</label>
                                <input
                                    type="text"
                                    className="shadow appearance-none border rounded w-full py-1 px-1 text-gray-700"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-4 md:gap-6 w-full">
                            <div className="mb-4 w-2/3">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                                <input
                                    type="email"
                                    className="shadow appearance-none border rounded w-full py-1 px-1 text-gray-700"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-4 w-2/3">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Mobile Number</label>
                                <input
                                    type="text"
                                    className="shadow appearance-none border rounded w-full py-1 px-1 text-gray-700"
                                    value={mobileNumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-4 md:gap-6 w-full">
                            <div className="mb-4 w-full">
                                <div className="flex justify-between">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
                                    <div
                                        className="pr-3 text-sm"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                                    </div>
                                </div>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    className="shadow appearance-none border rounded w-full py-1 px-1 text-gray-700"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="mb-4 w-full">
                                <div className="flex justify-between">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">Confirm Password</label><div
                                        className="pr-3 text-sm"
                                        onClick={() => setShowPassword1(!showPassword1)}
                                    >
                                        {showPassword1 ? <FaEye /> : <FaEyeSlash />}
                                    </div>
                                </div>
                                <input
                                    type={showPassword1 ? 'text' : 'password'}
                                    className="shadow appearance-none border rounded w-full py-1 px-1 text-gray-700"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />

                            </div>
                        </div>
                        <div className="mb-4">
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Register
                            </button>
                        </div>
                        <div className="text-center">
                            <a href="/signin" className="text-blue-500">Already an existing user? Click here</a>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default RegisterForm;
