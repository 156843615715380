import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Payments = () => {
    const emailAddress = 'support@sfsacademy.in';
    const phoneNumber = '+91 8983019596';

    // const handleButtonClick = () => {
    //     // Show Toast Notification
    //     toast.info('You will be redirected to the payment confirmation form', {
    //         position: "top-right",
    //         autoClose: 3000, // 5 seconds
    //     });

    //     // Navigate after 5 seconds
    //     setTimeout(() => {
    //         window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSc9xoUUOX5pJTzvrv9gBtl90Gw0Y8oz2nj91wZGaZEare6tTQ/viewform?usp=sf_link';
    //     }, 3000);
    // };

    return (
        <div>
            <div className="flex justify-center pt-10 mx-10 gap-2 mt-24">
                <div className="w-full md:w-1/2 bg-gray-200 m-2 p-4 rounded-lg border-2 border-black flex flex-col items-center">
                    <h1 className="text-2xl font-semibold mb-2">UPI QR Code and UPI-ID</h1>
                    <img src='/images/Qrfor payment.jpg' alt="SFS Academy Payment QR Code" className="w-auto max-h-[350px] mx-auto mb-4" />
                    <p className="bg-green-200 text-green-700 px-4 py-2 rounded-lg text-xl">Amount - ₹ 36,999/-</p>
                    <p className="text-center">You can pay the complete amount<br />OR Pay in Two installments as - ₹ 18,500 and ₹ 18,499</p>
                    <p>For any queries or doubts connect with us at <a className="text-blue-800" href={`mailto:${emailAddress}`}>{emailAddress}</a> or <a className="text-blue-800" href={`tel:${phoneNumber}`}>{phoneNumber}</a> </p>
                </div>
                <div className="w-full md:w-1/2 bg-gray-200 m-2 pt-20 rounded-lg border-2 border-black flex flex-col items-center text-center">
                    <h1 className="text-2xl font-semibold mb-4">Net Banking Details</h1>
                    <div className="bg-gray-100 rounded-lg p-4">
                    <p>Name :- SFS Academy</p>
                    <p>Nature of account :- Current</p>
                    <p>Account no.: 0948471399</p>
                    <p>IFSC :- KKBK0000725</p>
                    <p>Branch :- Empire Estate, Chinchwad, Pune</p>
                    </div>
                    <p className="bg-green-200 text-green-700 px-4 py-2 rounded-lg mt-4">Amount - ₹ 36,999/-</p>
                    <p className="text-center">You can pay the complete amount<br />OR Pay in Two installments as - ₹ 18,500 and ₹ 18,499</p>
                    <p>For any queries or doubts connect with us at <a className="text-blue-800" href={`mailto:${emailAddress}`}>{emailAddress}</a> or <a className="text-blue-800" href={`tel:${phoneNumber}`}>{phoneNumber}</a> </p>
                </div>
            </div>
            <div className="flex flex-col text-xl text-center font-semibold justify-center mb-10 mx-1 md:mx-28">
                <p>After completing the payment, send the payment receipt or screenshot on WhatsApp: {phoneNumber}</p>
                <p>For any queries or doubts connect with us at <a href={`mailto:${emailAddress}`}>{emailAddress}</a> or <a href={`tel:${phoneNumber}`}>{phoneNumber}</a> </p>
                {/* <button onClick={handleButtonClick}
                    className="mt-4 w-40 px-6 py-2 bg-teal-600 text-white rounded-lg transition duration-200 hover:bg-transparent hover:text-teal-600 hover:border-teal-600 borde">
                    Proceed to Confirm Admission
                </button> */}
                <ToastContainer />
            </div>
        </div>
    );
}

export default Payments;
