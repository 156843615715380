import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from 'jwt-decode';

const VideosTab = () => {
    const [category, setCategory] = useState('');
    const [videoName, setVideoName] = useState('');
    const [video, setVideo] = useState(null);
    const [videos, setVideos] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const CHUNK_SIZE = 70 * 1024 * 1024;

    const isTokenExpired = (token) => {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        return decodedToken.exp < currentTime;
    };

    useEffect(() => {
        fetchVideos();
    }, []);

    const fetchVideos = async () => {
        try {
            const token = localStorage.getItem('token');
            if (isTokenExpired(token)) {
                toast.error('Session expired. Please log in again.');
                navigate('/admin');
                return;
            }
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/videos`, {
                headers: {
                    Authorization: token,
                },
            });
            setVideos(response.data);
        } catch (err) {
            toast.error('Failed to fetch videos');
            if (err.response.status === 401) {
                navigate('/admin');
            }
        }
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        if (isTokenExpired(token)) {
            toast.error('Session expired. Please log in again.');
            navigate('/admin');
            return;
        }
        await uploadChunk(video, token);
    };

    const uploadChunk = async (file, token, retries = 3) => {
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        let uploadedChunks = 0;

        const upload = async (start) => {
            const chunk = file.slice(start, start + CHUNK_SIZE);
            const formData = new FormData();
            formData.append('category', category);
            formData.append('videoName', videoName);
            formData.append('chunk', chunk);
            formData.append('fileName', file.name);
            formData.append('start', start);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/upload-video-chunk`, formData, {
                    headers: {
                        Authorization: token,
                        'Content-Type': 'multipart/form-data',
                        'file-size': file.size,
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(((uploadedChunks + progressEvent.loaded / progressEvent.total) * 100) / totalChunks);
                        setUploadProgress(percentCompleted);
                    },
                    timeout: 0,
                });
                if (response.data.message) {
                    uploadedChunks += 1;
                    if (uploadedChunks < totalChunks) {
                        await upload(start + CHUNK_SIZE);
                    }
                } else {
                    throw new Error('Chunk upload failed');
                }
            } catch (err) {
                if (retries > 0) {
                    console.error(`Upload failed, retrying... (${retries} retries left)`, err);
                    await upload(start); // Retry the same chunk
                    retries -= 1;
                } else {
                    toast.error('Failed to upload video');
                    if (err.response?.status === 401) {
                        navigate('/admin');
                    }
                    return;
                }
            }
        };

        await upload(0);
        toast.success('Video uploaded successfully');
        setVideos([...videos, { category, video_name: videoName, id: Date.now() }]);
        setUploadProgress(0);
        // Reset form fields
        setCategory('');
        setVideoName('');
        setVideo(null);
        fileInputRef.current.value = '';
    };

    const handleRemoveVideo = async (videoId) => {
        try {
            const token = localStorage.getItem('token');
            if (isTokenExpired(token)) {
                toast.error('Session expired. Please log in again.');
                navigate('/admin');
                return;
            }
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/admin/delete-video/${videoId}`, {
                headers: {
                    Authorization: token,
                },
            });
            if (response.data.message) {
                toast.success(response.data.message);
                fetchVideos();
            }
        } catch (err) {
            toast.error('Failed to remove video');
            if (err.response.status === 401) {
                navigate('/admin');
            }
        }
    };

    return (
        <div className="p-2">
            <form onSubmit={handleUpload} className="space-y-4 text-center border-2">
                <h2 className="text-xl font-semibold bg-blue-100 p-2 mb-4">Upload New Video</h2>
                <div className="flex flex-col md:flex-row m-2 space-y-4 md:space-y-0 md:space-x-4">
                    <div className="flex flex-row items-center md:w-1/2">
                        <label className="font-medium mb-1">Category</label>
                        <input
                            type="text"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className="p-2 mx-2 w-full border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="flex flex-row items-center md:w-1/2">
                        <label className="font-medium mb-1">Video Name</label>
                        <input
                            type="text"
                            value={videoName}
                            onChange={(e) => setVideoName(e.target.value)}
                            className="p-2 mx-2 w-full border border-gray-300 rounded"
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row m-2 items-center space-y-2 md:space-y-0 md:space-x-2">
                    <label className="font-medium mb-1">Upload Video</label>
                    <input
                        type="file"
                        onChange={(e) => setVideo(e.target.files[0])}
                        className="p-2 max-w-64 overflow-hidden border border-gray-300 rounded"
                        required
                        ref={fileInputRef}
                    />
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                        Upload
                    </button>
                </div>
                {uploadProgress > 0 && <div>Upload Progress: {uploadProgress}%</div>}
            </form>

            <h2 className="text-xl text-center bg-blue-100 p-2 font-semibold my-4">Uploaded Videos</h2>
            <div className="overflow-x-auto">
                <table className="w-full text-center bg-white">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Category</th>
                            <th className="py-2 px-4 border-b">Video Name</th>
                            <th className="py-2 px-4 border-b">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {videos.map((video) => (
                            <tr key={video.id}>
                                <td className="py-2 px-4 border-b">{video.category}</td>
                                <td className="py-2 px-4 border-b">{video.video_name}</td>
                                <td className="py-2 px-4 border-b">
                                    <button
                                        className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                                        onClick={() => handleRemoveVideo(video.id)}
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ToastContainer />
        </div>
    );
};

export default VideosTab;
