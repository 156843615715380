import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const ProtectedStudRoute = () => {
  const isAuthenticated = localStorage.getItem('token1') !== null;

  return isAuthenticated ? <Outlet /> : <Navigate to="/signin" />;
};

export default ProtectedStudRoute;
