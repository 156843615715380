// Resources.js
import React, { useState } from 'react';
import Lectures from './lectures';
import Notes from './notes';

const Resources = () => {
    const [activeSection, setActiveSection] = useState(1);

    const handleSectionClick = (sectionNumber) => {
        setActiveSection(sectionNumber);
    };

    return (
        <div className="min-h-[80vh] mt-24 bg-white pt-6 p-4">
            <Menu activeSection={activeSection} onSectionClick={handleSectionClick} />
            <div className="content mt-4">
                {activeSection === 1 && <Lectures />}
                {activeSection === 2 && <Notes />}
            </div>
        </div>
    );
};

const Menu = ({ activeSection, onSectionClick }) => {
    return (
        <div className="flex justify-around mb-4">
            <button
                className={`text-2xl font-semibold text-blue-600 w-1/2 py-2 relative ${activeSection === 1 ? 'bg-blue-100' : 'bg-transparent'}`}
                onClick={() => onSectionClick(1)}
            >
                Lectures
                <span className={`absolute bottom-0 left-0 w-full h-1 bg-blue-600 transform transition-transform ${activeSection === 1 ? 'scale-x-100' : 'scale-x-0'}`}></span>
            </button>
            <button
                className={`text-2xl font-semibold text-blue-600 w-1/2 py-2 relative ${activeSection === 2 ? 'bg-blue-100' : 'bg-transparent'}`}
                onClick={() => onSectionClick(2)}
            >
                Notes
                <span className={`absolute bottom-0 left-0 w-full h-1 bg-blue-600 transform transition-transform ${activeSection === 2 ? 'scale-x-100' : 'scale-x-0'}`}></span>
            </button>
        </div>
    );
};

export default Resources;
