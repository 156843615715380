import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const StudentsTab = () => {
    const [newStudents, setNewStudents] = useState([]);
    const [registeredStudents, setRegisteredStudents] = useState([]);
    const navigate = useNavigate();

    const isTokenExpired = (token) => {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        return decodedToken.exp < currentTime;
    };

    useEffect(() => {
        const fetchNewStudents = async () => {
            try {
                const token = localStorage.getItem('token');
                if (isTokenExpired(token)) {
                    toast.error('Session expired. Please log in again.');
                    navigate('/admin');
                    return;
                }
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/new-students`, {
                    headers: {
                        Authorization: token,
                    },
                });
                setNewStudents(response.data);
            } catch (err) {
                toast.error('Failed to fetch new students');
                if (err.response.status === 401) {
                    navigate('/admin');
                }
            }
        };

        fetchNewStudents();
    }, [navigate]);

    useEffect(() => {
        const fetchRegisteredStudents = async () => {
            try {
                const token = localStorage.getItem('token');
                if (isTokenExpired(token)) {
                    toast.error('Session expired. Please log in again.');
                    navigate('/admin');
                    return;
                }
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/registered-students`, {
                    headers: {
                        Authorization: token,
                    },
                });
                setRegisteredStudents(response.data);
            } catch (err) {
                toast.error('Failed to fetch registered students:');
                if (err.response.status === 401) {
                    navigate('/admin');
                }
            }
        };

        fetchRegisteredStudents();
    }, [navigate]);

    const handleAcceptStudent = async (studentId) => {
        try {
            const token = localStorage.getItem('token');
            if (isTokenExpired(token)) {
                toast.error('Session expired. Please log in again.');
                navigate('/admin');
                return;
            }
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/accept-student`,
                { studentId },
                {
                    headers: {
                        Authorization: token,
                    },
                });
            if (response.data.success) {
                toast.success('Student accepted successfully');
                setNewStudents(newStudents.filter((student) => student.id !== studentId)); // Refresh the list
            } else {
                toast.error('Failed to accept student');
            }
        } catch (error) {
            toast.error('Failed to accept student');
        }
    };

    const handleRemoveStudent = async (studentId) => {
        try {
            const token = localStorage.getItem('token');
            if (isTokenExpired(token)) {
                toast.error('Session expired. Please log in again.');
                navigate('/admin');
                return;
            }
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/remove-student`,
                { studentId },
                {
                    headers: {
                        Authorization: token,
                    },
                });
            if (response.data.success) {
                toast.success('Student removed successfully');
                setRegisteredStudents(registeredStudents.filter((student) => student.id !== studentId))
            } else {
                toast.error('Failed to remove student');
            }
        } catch (error) {
            toast.error('Failed to remove student');
        }
    };

    return (
        <div className="p-2">
            <h2 className="text-xl text-center font-semibold bg-blue-100 py-2">New Registered Students</h2>
            <div className='overflow-x-auto'>
                <table className="w-full text-center border min-h-24 bg-white">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Name</th>
                            <th className="py-2 px-4 border-b">Email</th>
                            <th className="py-2 px-4 border-b">Mobile Number</th>
                            <th className="py-2 px-4 border-b">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {newStudents.map((student) => (
                            <tr key={student.id}>
                                <td className="py-2 px-4 border-b">{student.firstName} {student.lastName}</td>
                                <td className="py-2 px-4 border-b">{student.email}</td>
                                <td className="py-2 px-4 border-b">{student.mobileNumber}</td>
                                <td className="py-2 px-4 border-b">
                                    <button
                                        className="bg-green-500 text-white px-4 py-1 rounded hover:bg-green-600"
                                        onClick={() => handleAcceptStudent(student.id)}
                                    >
                                        Accept
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <h2 className="text-xl text-center font-semibold bg-blue-100 py-2">Academy Students</h2>
            <div className='overflow-x-auto'>
                <table className="w-full text-center border min-h-24 bg-white">
                    <thead>
                        <tr>
                            <th className='py-2 px-4 border-b'>Name</th>
                            <th className='py-2 px-4 border-b'>Email</th>
                            <th className='py-2 px-4 border-b'>Mobile Number</th>
                            <th className='py-2 px-4 border-b'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {registeredStudents.map((student) => (
                            <tr key={student.id}>
                                <td className='py-2 px-4 border-b'>{student.firstName} {student.lastName}</td>
                                <td className='py-2 px-4 border-b'>{student.email}</td>
                                <td className='py-2 px-4 border-b'>{student.mobileNumber}</td>
                                <td className='py-2 px-4 border-b'>
                                    <button
                                        className="bg-red-500 text-white px-4 py-2 rounded"
                                        onClick={() => handleRemoveStudent(student.id)}
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ToastContainer />
        </div>
    );
};

export default StudentsTab;