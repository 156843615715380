import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar';
import Home from './components/home';
import Course from './components/course.js';
import RegisterForm from './components/user/registration';
import Payments from './components/user/payment';
import Signin from './components/user/signin'
import ForgotPassword from './components/user/forgotpassword.js';
import ResetPassword from './components/user/resetpassword.js';
import Resources from './components/resources/resources.js'
import ProtectedStudRoute from './components/protectedstudroute.js';
// import Reviews from './components/reviews.js';
import Footer from './components/footer';
import AdminLogin from './components/admin/adminlogin';
import AdminDashboard from './components/admin/admindashboard';
import ProtectedAdminRoute from './components/protectedadminroute.js';
import TermsAndConditions from './components/termsandconditions.js';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/course' element={<Course />} />
            <Route path='/admin' element={<AdminLogin />} />
            <Route element={<ProtectedAdminRoute />}>
              <Route path='/admin/dashboard' element={<AdminDashboard />} />
            </Route>
            <Route path='/signin' element={<Signin />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route element={<ProtectedStudRoute />}>
              <Route path='/resources' element={<Resources />} />
            </Route>
            <Route path='/registration' element={<RegisterForm />} />
            <Route path='/payment' element={<Payments />} />
            {/* <Route path='/reviews' element={<Reviews />} /> */}
            <Route path='/termsandcondition' element={<TermsAndConditions />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
