import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const ProtectedAdminRoute = () => {
  const isAuthenticated = localStorage.getItem('token') !== null;

  return isAuthenticated ? <Outlet /> : <Navigate to="/admin" />;
};

export default ProtectedAdminRoute;
