import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
    {
        id: 1,
        name: 'Dr. Prachi Modi',
        rating: 5,
        text: "I'm Dr. Prachi Modi, a Neuro Physiotherapist, had joined SFS Academy with Dr. Faisal. The concepts were presented in a way that made them easy to grasp, and was relevant to the patients we treat on daily basis. The lectures were engaging and interactive, and he gave most appropriate rationales to all the doubts. His approach has changed how I practice, making it more patient-centered and outcome-focused. This has improved my communication with patients and increased my patient conversion rate. His guidance has also increased my interest in other areas of physiotherapy, helping me become a more skilled and well-rounded physiotherapist.",
    },
    {
        id: 2,
        name: 'Dr. Tejas Tijage',
        rating: 4,
        text: "Joining SFS Academy's under the guidance of Dr. Faisal has been an exceptional experience. Dr. Faisal's expertise and engaging teaching style made complex concepts accessible and practical. The course was well-structured, combining comprehensive theoretical knowledge with hands-on exercises that were both relevant and insightful. Dr. Faisal's supportive feedback and willingness to answer questions added immense value to the learning process. The flexible online format allowed me to manage my study schedule effectively, while the quality of instruction ensured a deep and thorough understanding of physiotherapy principles. I highly recommend this course to anyone looking to excel in the field, thanks to the outstanding mentorship provided by Dr. Faisal.",
    },
    {
        id: 3,
        name: 'Dr. Rishika Shrivastava',
        rating: 5,
        text: "Myself Dr Rishika, a physio having 15 years of experience.I had joined SFS academy to upgrade my knowledge and revision of my entire subjects in crash course manner. I found course content very structured and comprehensive. The way lectures were delivered by Dr Faisal was commendable. No matter how many times we raise our doubt, it was answered with patience always. I must appreciate the rationale behind every answer. I am happy and satisfied with my decision of enrollment in this course. Thank you",
    },
    {
        id: 4,
        name: 'Dr. Ruchi Hakani',
        rating: 5,
        text: 'The training was very helpful for our daily practice. It was very conceptual and that was the best part. In addition to that we also got to learn manipulation which was a plus. Only one suggestion that training should be digitalize(online training).',
    },
    {
        id: 5,
        name: 'Dr. Anjuna Nair',
        rating: 5,
        text: 'The session was absolutely super and interactive ,would like to her more the most interesting part was Q and A where everyones perception was measured and sir gave us a path....great 👍👍👍.',
    },
    {
        id: 6,
        name: 'Dr. Alee Memon',
        rating: 5,
        text: 'The session highlighted concepts that are not extensively taught academically but has clinical relevance and applications. Using case examples in between made it easier to relate. To be more vigilant about the biomechanical aspects of movement and how it can benefit clinical outcomes.',
    },
];

const videoTestimonials = [
    { name: 'Dr. Tanaya Mhatre', url: '/images/Dr. Tanaya.mp4' },
    { name: 'Dr. Mohini Kolpe', url: '/images/Dr. Mohini.mp4' },
    { name: 'Dr. Tejas Tijage', url: 'images/Dr. Tejas.mp4'}
]

const Home = () => {
    const sliderRef = useRef(null);
    const videoRefs = useRef([]);
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
    };

    const videoSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        controlsList: 'nodownload',
        disablePictureInPicture: true,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    };

    // IntersectionObserver API to trigger autoplay
    useEffect(() => {
        const observerOptions = {
            root: null, // viewport
            threshold: 0.5, // trigger when 50% of the video section is in view
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    videoRefs.current[currentSlide]?.play();
                } else {
                    videoRefs.current[currentSlide]?.pause();
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        const videoElement = videoRefs.current[currentSlide];
        if (videoElement) {
            observer.observe(videoElement);
        }

        return () => {
            if (videoElement) observer.unobserve(videoElement);
        };
    }, [currentSlide]);

    // Handling Video End: move to the next slide
    const handleVideoEnd = () => {
        const nextSlide = currentSlide + 1 < videoTestimonials.length ? currentSlide + 1 : 0;
        sliderRef.current.slickGoTo(nextSlide);
    };

    return (
        <div>
            <section className="mt-24 bg-cover bg-center h-[50vh] sm:h-[65vh]" style={{ backgroundImage: "url('/images/banner2.png')" }}>
                <div className="flex items-center justify-center h-full bg-black bg-opacity-50 px-0">
                    <div className="text-center flex-col w-full md:w-[78%] mx-auto md:mt-12 pt-4">
                        <h1 className="text-xl md:text-3xl text-white mx-6 mb-2 font-bold" style={{ textShadow: '1px 1px 10px rgba(215, 215, 215, 0.86)' }}>Welcome to SFS Academy<br />Your Gateway to Excellence in Physiotherapy</h1>
                        <p className="text-justify text-white font md:font-semibold text-base md:text-xl mx-4 sm:mx-8 md:mr-12" style={{ textShadow: '2px 2px 4px rgba(215, 215, 215, 0.86)' }}>
                            At SFS Academy, we are dedicated to transforming the landscape of Physiotherapy education through innovative and accessible online learning.
                            Our aim is to empower Physiotherapy (PT) students & practioners, with high-quality, flexible, and engaging educational experiences that cater
                            to diverse needs and practice styles.</p>
                        <div className="mt-4 text-center">
                            <Link to="/course">
                                <button className="bg-green-500 text-white font-bold shadow-white shadow-sm py-2 px-4 mt-2 rounded-md hover:bg-green-700 transition-colors">
                                    Start Your Journey
                                </button>
                            </Link>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </section>
            <div className='my-10 pt-10 mx-4 md:mx-12'>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div className="bg-white mx-3 rounded-[40px] overflow-hidden min-h-[425px] shadow-[0_0_15px_0_rgba(10,10,10,0.3)]">
                        <div style={{ backgroundImage: 'url(/images/vision.webp)' }} className=" min-h-[230px] bg-no-repeat bg-cover bg-center">
                        </div>
                        <div className='p-4 pt-1'>
                            <h4 className='text-center text-xl font-semibold'>Vision</h4>
                            <p className='text-justify text-sm sm:text-base'>We envision a system where quality education is accessible, empowering individuals to achieve their full potential
                                regardless of their location or background.<br /> We strive to break down barriers to learning and create opportunities for personal and professional growth.</p>
                        </div>
                    </div>

                    <div className='bg-white mx-3 rounded-[40px] overflow-hidden min-h-[425px] shadow-[0_0_15px_rgba(10,10,10,0.3)]'>
                        <div style={{ backgroundImage: 'url(/images/mission.webp)' }} className=" min-h-[230px] bg-no-repeat bg-cover bg-center">
                        </div>
                        <div className='p-4 pt-1'>
                            <h4 className='text-center text-xl font-semibold'>Mission</h4>
                            <p className='text-justify text-sm sm:text-base'>Our mission is to deliver top-notch online educational resources that inspire and equip PT students & practioners
                                with the skills and knowledge needed to thrive in today’s ever-evolving world. We are committed to delivering:</p>
                            <ul className="list-disc text-sm sm:text-base ml-5 space-y-1">
                                <li>Broad Range of expertise</li>
                                <li>Expert Guidance</li>
                                <li>Flexible Learning</li>
                                <li>Engaging Resources</li>
                                <li>Supportive Network</li>
                            </ul>
                        </div>
                    </div>

                    <div className='bg-white mx-3 rounded-[40px] overflow-hidden min-h-[425px] shadow-[0_0_15px_rgba(10,10,10,0.3)]'>
                        <div style={{ backgroundImage: 'url(/images/values.webp)' }} className=" min-h-[230px] bg-no-repeat bg-cover bg-center">
                        </div>
                        <div className='p-4 pt-1'>
                            <h4 className='text-center text-xl font-semibold'>Values</h4>
                            <p className='text-justify text-sm sm:text-base'>Our values define our commitment to making education accessible, fostering diversity, and maintaining high ethical standards.
                                We aim to empower learners to succeed both personally and professionally. We uphold:</p>
                            <ul className="list-disc text-sm sm:text-base ml-5 space-y-1">
                                <li>Democratizing Education</li>
                                <li>Pursuing Excellence</li>
                                <li>Celebrating Diversity</li>
                                <li>Ethical Integrity</li>
                                <li>Empowering Success</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-2 my-4 py-4">
                <div className="flex flex-col md:flex-row rounded-2xl"> {/*bg-blue-900 */}
                    <div className="flex-col order-2 md:w-1/2 md:order-1 md:ml-20 mt-4 font-semibold">
                        <h1 className='text-4xl gradient-text pb-2 mb-2'>Why Choose SFS Academy?</h1>
                        <ul className="list-disc ml-5 space-y-1">
                            <li>At SFS Academy, you’re not just enrolling in a course; you’re joining a community dedicated to lifelong learning and personal development. </li>
                            <li>Expert guidance from experienced professionals with extensive clinical exposure</li>
                            <li>Passionate leadership dedicated to addressing concerns and uncertainties</li>
                            <li>Emphasis on refining management skills for seamless transition from academia to clinical practice.</li>
                            <li>Bridging the gap between academic and clinical knowledge for real-world application.</li>
                            <li>Empowering students to excel in their practiceA thorough understanding of what is required for enhancing clinical practice.</li>
                        </ul>
                    </div>
                    <div className="w-full sm:w-1/3 md:w-1/2 order-1 md:order-2 content-center sm:mr-8 md:mr-12 ">
                        <img src='/images/womanphysio.webp' alt="Physiotherapy1" className="sm:w-5/6 max-h-80 rounded-2xl mx-auto mb-2" />
                    </div>
                </div>
            </div>
            {/* <div className="container mx-auto my-2 px-4">
                <div className="flex flex-col md:flex-row rounded-2xl m-2">
                    <div className="sm:w-1/3 md:w-1/2 order-1 mx-4 object-center content-center md:ml-12">
                        <img src='/images/phisioimg.webp' alt="Physiotherapy2" className="w-auto max-h-80 mx-auto rounded-xl" />
                    </div>
                    <div className="flex order-2 md:w-1/2 md:mr-12 font-semibold">
                        <div>
                            <h1 className='text-4xl mt-2 gradient-text py-2'>Enhancing your Physiotherapy Expertise</h1> 
                            <p className='mt-2'>
                                Unlock a wealth of knowledge with our diverse resources, including live lectures, structured study materials,
                                and innovative problem-solving strategies. Our high-quality, affordable online tutoring is delivered by
                                experienced professionals with extensive clinical backgrounds. Our well-curated content ensures you gain
                                fresh perspectives with practical relevance, enhancing your clinical practice.
                            </p>
                            <div className="gradient-text flex flex-row gap-4">
                                <div className="w-full lg:w-1/2 text-center">
                                    <h3 className="text-xl font-semibold text-sky-700">Interactive</h3>
                                    <h1 className="text-2xl font-extrabold text-sky-700">Online</h1>
                                    <h3 className="text-xl font-semibold text-sky-700">Lectures</h3>
                                </div>
                                <div className="w-full lg:w-1/2 text-center">
                                    <h3 className="text-xl font-semibold text-sky-700">Trained</h3>
                                    <h1 className="text-2xl font-extrabold text-sky-700">150+</h1>
                                    <h3 className="text-xl font-semibold text-sky-700">Physiotherapists</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container rounded-3xl md:w-4/5 mx-auto py-8 p-2 m-10" style={{ background: "linear-gradient(130deg,#0800ff, #00aaff)" }}>
                {/* <div className="flex flex-wrap m-8"> */}
                <div className="w-full text-center">
                    <h1 className="text-2xl md:text-4xl font-semibold text-white mb-4">Explore Our Comprehensive Courses</h1>
                    <p className="md:text-lg text-white mb-4">Discover how SFS Academy can help you achieve & upgrade your professional & educational goals.
                        <br />Unlock a range of specialized physiotherapy courses covering Musculoskeletal, Cardio Pulmonary, Neurology, Sports and more.
                        <br />Connect with our community, and advance your skills today.</p>
                    <a href="/course" className="inline-block bg-green-500 text-white rounded-lg px-6 py-3 text-lg font-semibold hover:bg-green-600 transition-colors">
                        View Full Course
                    </a>
                </div>
            </div>
            <div className="container relative min-h-52 my-6 pb-5 mx-auto px-4 md:px-10">
                <div className="h-2 bg-gradient-to-r rounded-xl from-blue-500 via-green-500 to-blue-300 w-full"></div>
                <h1 className="text-3xl font-serif font-bold gradient-text pt-8 text-center">Hear from Our Successful Alumni.</h1>
                <Slider ref={sliderRef} className='w-[95%] md:w-full pt-6' {...videoSettings}>
                    {videoTestimonials.map((video, index) => (
                        <div key={index}>
                            <div className="flex flex-col md:flex-row justify-evenly shadow-lg bg-gray-100 rounded-lg p-2">
                                <video ref={(el) => (videoRefs.current[index] = el)}
                                    className="md:w-2/5 h-[400px] rounded-2xl bg-current"
                                    onEnded={handleVideoEnd} controlsList="nodownload" disablePictureInPicture
                                    playsInline controls>
                                    <source src={video.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='flex flex-col justify-center'>
                                    <h2 className="text-gray-900 font-serif text-xl mt-2 md:text-3xl font-semibold text-center">{video.name}</h2>
                                    <div className="h-1 w-full rounded-md bg-gradient-to-r from-green-400 to-blue-500"></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className='container bg-gray-200 rounded-2xl min-h-52 md:w-4/5 my-10 pb-8 mx-auto px-10'>
                <h2 className="text-2xl py-2 text-center">Testimonials</h2>
                <Slider className='w-[100%]' {...settings}>
                    {testimonials.map((testimonial) => (
                        <div key={testimonial.id} className="bg-white shadow-lg rounded-lg p-4">
                            <div className='flex flex-row justify-evenly'>
                                <h3 className="text-gray-900 font-semibold text-center">{testimonial.name}</h3>
                                <div className="flex justify-center mb-4">
                                    {[...Array(5)].map((_, starIndex) => (
                                        <svg
                                            key={starIndex}
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill={starIndex < testimonial.rating ? "gold" : "gray"}
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 17.25l-5.093 2.675 1.077-6.281L3 8.843l6.314-.916L12 2.25l2.686 5.677L21 8.843l-4.984 4.801 1.077 6.281L12 17.25z"
                                            />
                                        </svg>
                                    ))}
                                </div>
                            </div>
                            <h4 className="text-gray-700 text-center text-sm sm:text-base mb-4">{testimonial.text}</h4>
                        </div>
                    ))}
                </Slider>
                {/* Add reviews content here */}
            </div>

        </div>
    );
};

export default Home;