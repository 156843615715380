// AdminDashboard.js
import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import StudentsTab from './studentstab';
import VideosTab from './videostab';
import NotesTab from './notestab';

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState('students');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className="min-h-[80vh] mt-24 pt-4 p-2">
            <h1 className="text-3xl gradient-text mt-4 font-bold mb-4 text-center">Admin Dashboard</h1>
            <Menu activeTab={activeTab} onTabClick={handleTabClick} />
            <div className="content">
                {activeTab === 'students' && <StudentsTab />}
                {activeTab === 'videos' && <VideosTab />}
                {activeTab === 'notes' && <NotesTab />}
            </div>
        </div>
    );
};

const Menu = ({ activeTab, onTabClick }) => {
    return (
        <div className="flex bg-white border-2 rounded-lg shadow-md justify-around">
            <button
                className={`text-xl font-semibold text-blue-600 w-1/3 py-2 relative ${activeTab === 'students' ? 'bg-blue-100' : 'bg-transparent'}`}
                onClick={() => onTabClick('students')}
            >
                Students
                <span className={`absolute bottom-0 left-0 w-full h-1 bg-blue-600 transform transition-transform ${activeTab === 'students' ? 'scale-x-100' : 'scale-x-0'}`}></span>
            </button>
            <button
                className={`text-xl font-semibold border-x-2 text-blue-600 w-1/3 py-2 relative ${activeTab === 'videos' ? 'bg-blue-100' : 'bg-transparent'}`}
                onClick={() => onTabClick('videos')}
            >
                Videos
                <span className={`absolute bottom-0 left-0 w-full h-1 bg-blue-600 transform transition-transform ${activeTab === 'videos' ? 'scale-x-100' : 'scale-x-0'}`}></span>
            </button>
            <button
                className={`text-xl font-semibold text-blue-600 w-1/3 py-2 relative ${activeTab === 'notes' ? 'bg-blue-100' : 'bg-transparent'}`}
                onClick={() => onTabClick('notes')}
            >
                Notes
                <span className={`absolute bottom-0 left-0 w-full h-1 bg-blue-600 transform transition-transform ${activeTab === 'notes' ? 'scale-x-100' : 'scale-x-0'}`}></span>
            </button>
        </div>
    );
};

export default AdminDashboard;