import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from 'jwt-decode';

const Lectures = () => {
    const [videos, setVideos] = useState([]);
    const [videoCategories, setVideoCategories] = useState([]);
    const [expandedCategory, setExpandedCategory] = useState(null);
    const navigate = useNavigate();

    const isTokenExpired = (token1) => {
        const decodedToken = jwtDecode(token1);
        const currentTime = Date.now() / 1000; // Current time in seconds
        return decodedToken.exp < currentTime;
    };

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const token1 = localStorage.getItem('token1');
                if (isTokenExpired(token1)) {
                    toast.error('Session expired. Please log in again.');
                    navigate('/signin');
                    return;
                }
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/student/videos`, {
                    headers: {
                        Authorization: token1,
                    },
                });
                setVideos(response.data)
                const categorizedVideos = categorizeVideos(response.data);
                setVideoCategories(categorizedVideos);
            } catch (err) {
                toast.error('Failed to fetch videos');
                if (err.response.status === 401) {
                    navigate('/signin');
                }
            }
        };

        fetchVideos();
    }, [navigate]);

    const categorizeVideos = (data) => {
        const categorized = data.reduce((acc, video) => {
            let category = acc.find(cat => cat.category === video.category);
            if (!category) {
                category = { category: video.category, files: [] };
                acc.push(category);
            }

            category.files.push({
                id: video.id,
                video_name: video.video_name,
                file_path: `/videos/${video.file_path}`,
                upload_date: video.upload_date,
            });
            return acc;
        }, []);

        return categorized;
    };

    const toggleCategory = (category) => {
        setExpandedCategory(expandedCategory === category ? null : category);
    };

    const handleVideoClick = (videoPath) => {
        const videoContainer = document.createElement('div');
        videoContainer.style.position = 'fixed';
        videoContainer.style.top = '0';
        videoContainer.style.left = '0';
        videoContainer.style.width = '100%';
        videoContainer.style.height = '100%';
        videoContainer.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
        videoContainer.style.zIndex = '1000';
        videoContainer.style.display = 'flex';
        videoContainer.style.alignItems = 'center';
        videoContainer.style.justifyContent = 'center';

        const videoElement = document.createElement('video');
        videoElement.src = videoPath;
        videoElement.controls = true;
        videoElement.disablePictureInPicture = true;
        videoElement.style.width = '80%';
        videoElement.style.height = '80%';
        videoElement.style.backgroundColor = 'black';
        videoElement.style.zIndex = '1000';
        videoElement.autoplay = false;
        videoElement.controlsList = 'nodownload';

        videoElement.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        videoContainer.appendChild(videoElement);

        videoContainer.addEventListener('click', (e) => {
            if (e.target === videoContainer){
            document.body.removeChild(videoContainer);
            }
        });

        document.body.appendChild(videoContainer);
    };

    return (
        <div>
            <h2 className="text-xl text-center gradient-text font-semibold mb-4">Lecture Videos</h2>
            {videoCategories.map((category) => (
                <div className='bg-gray-200 border border-black m-2 md:mx-20 pt-2 rounded-lg text-center' key={category.category}>
                    <h3 className='text-xl gradient-text font-semibold mb-2 cursor-pointer'
                    onClick={() => toggleCategory(category.category)}>{category.category}</h3>
                    {expandedCategory === category.category && (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 border-t border-black bg-gray-50 rounded-b-lg py-3 px-4">
                        {category.files.map((video) => (
                            <div key={video.id} className="flex border p-2 rounded shadow cursor-pointer"
                                onClick={() => handleVideoClick(video.file_path)}>
                                <div className='flex-shrink-0'>
                                    <svg className="w-8 h-8 text-green-500" fill="currentColor" viewBox="0 0 18 20">
                                        <path d="M4.5 1A1.5 1.5 0 003 2.5v15A1.5 1.5 0 004.5 19h11a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0015.5 1h-11zM14 10l-6 4V6l6 4z"></path>
                                    </svg>
                                </div>
                                <div className='ml-4'>
                                    <h4 className='font-medium'>{video.video_name}</h4>
                                </div>
                            </div>
                        ))}
                    </div>
                    )}
                </div>
            ))}
            <ToastContainer />
        </div>
    );
};

export default Lectures;
