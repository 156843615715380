import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token3 = new URLSearchParams(location.search).get('token3')
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast.error('Passwords do not match.', { position: "top-right" });
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/reset-password`, { token3, password });
            if (response.data.success) {
                toast.success('Password reset successful!', { position: "top-right" });
                navigate('/signin');
            } else {
                toast.error('Failed to reset password.', { position: "top-right" });
            }
        } catch (err) {
            console.error('Reset password error:', err);
            toast.error('Failed to reset password. Please try again later.', { position: "top-right" });
        }
    };

    return (
        <div className="min-h-[75vh] mt-24 pt-4 flex flex-col items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
                <h2 className="text-2xl font-bold mb-8 text-center">Reset Password</h2>
                <form onSubmit={handleResetPassword}>
                    <div className="mb-4">
                        <div className='flex justify-between'>
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                New Password
                            </label>
                            <div
                                className="pr-3 text-sm"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </div>
                        </div>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter your new password"
                        />
                    </div>
                    <div className="mb-6">
                        <div className='flex justify-between'>
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                                Confirm Password
                            </label>
                            <div
                                className="pr-3 text-sm"
                                onClick={() => setShowPassword1(!showPassword1)}
                            >
                                {showPassword1 ? <FaEye /> : <FaEyeSlash />}
                            </div>
                        </div>
                        <input
                            type={showPassword1 ? 'text' : 'password'}
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Confirm your new password"
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Reset Password
                        </button>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </div>
    );
};

export default ResetPassword;
