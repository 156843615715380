import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

const Course = () => {
    const navigate = useNavigate();
    const [isChecked, setChecked] = useState(false);

    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };

    const handleEnrollment = () => {
        if (isChecked) {
            handleGotoRegister();
        } else {
            alert("Please agree to the terms and conditions to proceed.");
        }
    };

    const handleGotoRegister = () => {
        navigate('/registration');
    };

    return (
        <>
            <div className="container mt-24 mx-auto p-2">
                <h2 className="text-3xl gradient-text font-bold text-center p-2 mt-6 mb-4">Physiotherapy Tutorial Program</h2>
                <div className="flex flex-col md:flex-row md:ml-8">
                    <div className="flex flex-col items-center">
                        <div className="w-[85%] p-2">
                            <h3 className="text-2xl font-bold mb-4">Course Content</h3>
                            <ul className="list-disc text-gray-70 md:font-medium text-sm ml-5 md:ml-8 space-y-1">
                                <li>Assessment guidelines (all specialties)</li>
                                <li>MSK Examination & Intervention</li>
                                <li>Neurology Examination & Intervention</li>
                                <li>Cardiology Examination & Intervention</li>
                                <li>Respiratory Examination & Intervention</li>
                                <li>Pediatrics Examination & Intervention</li>
                                <li>Sports injury Examination & Intervention</li>
                                <li>Gait Examination & Intervention</li>
                                <li>Skin Examination & its co-relation in Physiotherapy</li>
                                <li>Spine Impairments & Interventions</li>
                                <li>Spinal cord injury & Intervention</li>
                                <li>PNI examination, Intervention & Clinical implications</li>
                                <li>PT in cancer patients</li>
                                <li>Vitamin deficiency in rehabilitation </li>
                                <li>Biomechanics (Joint Specific) </li>
                                <li>Multi system 1 (PVD, Skin) intervention</li>
                                <li>Multisystem 2 (GI tract, Liver, Pancreas, Spleen) referred pain</li>
                                <li>Multisystem 3 (Metabolic disorder, Pregnancy, Incontinence)</li>
                                <li>Orthosis & prosthesis (uses & gait deviation)</li>
                                <li>Ethics in Physiotherapy Practice</li>
                                <li>Safety & Equipment in physiotherapy</li>
                                <li>Conservative & post-operative PT management (UL & LL fractures, tendon, ligament, meniscus repair)</li>
                                <li>Organ transplant Rehabilitation </li>
                                <li>Blood parameters and its importance in physiotherapy</li>
                                <li>Body positioning & its clinical importance</li>
                                <li>Q & A training for license exam (PCE & DHA) </li>
                                <li>Company formation, business development & management structure</li>
                                <li>Personality, skill & communication development</li>
                            </ul>
                        </div>
                        <div className="w-4/5 m-4 p-3 rounded-xl border-2 border-gray-700">
                            <h3 className="text-2xl font-bold">KEY TAKEAWAYS</h3>
                            <ul className="list-disc text-gray-700 font-medium text-sm ml-5 space-y-1">
                                <li>Clinical reasoning  </li>
                                <li>Decision making </li>
                                <li>Ethical practice </li>
                                <li>Communication & negotiation skills </li>
                                <li>Lifetime Mentorship </li>
                                <li>Transforming process </li>
                                <li>Business development</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-col mt-4 items-center">
                        <div className="flex flex-col items-center border-2 border-gray-600 rounded-xl md:w-[82%] mb-4">
                            <div className="text-white text-sm p-2 m-2 rounded-lg shadow-black shadow-md w-[95%]" style={{ background: "linear-gradient(130deg,#0800ff, #00aaff)" }}>
                                <h1 className="text-xl font-semibold mb-2">Course Name:</h1>
                                <p className="font-medium">Physiotherapy Tutorial Program</p>
                                <ul className="list-disc text-white mt-2 ml-5 space-y-1">
                                    <li>35 + interactive lectures (2 hours) </li>
                                    <li>Mode: Online (5 months program)</li>
                                    <li>Weekly Lectures (Thurs & Sun)</li>
                                    <li>Fee: 37,000/- (installments aval)</li>
                                    <li>Program accessibility for 1 year</li>
                                    <li>Study material</li>
                                    <li>Program orientation & website access on  28/09/2024</li>
                                    <li>Lectures starting from  29/09/2024</li>
                                </ul>
                                {/* <p>[5 months live lectures (Program accessible for one year)]</p>
                                <h2 className="text-xl font-semibold mt-2">Description:</h2>
                                <p>It covers all specialities of physical therapy components of academic, clinical and recent advances.</p> */}
                                <h2 className="text-lg font-semibold mt-2">Eligibility Criteria:</h2>
                                <p>Third Year, Final Year, Bachelors & Masters of Physiotherapy all are eligible for joining this online tutorial.</p>
                            </div>
                            <div className="bg-green-600 text-white p-2 m-2 rounded-lg w-4/5">
                                <h2 className="text-lg text-center font-semibold">Fees Structure: ₹ 36,999/-</h2>
                            </div>
                            <div className="bg-orange-100 p-2 mb-2 mx-2 rounded-lg flex items-center md:w-3/4">
                                <div className="flex items-center mb-2">
                                    <input
                                        type="checkbox"
                                        id="termsCheckbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        className="mr-2"
                                    />
                                    <a href='/termsandcondition' className="text-sm" htmlFor="termsCheckbox">
                                        I agree to the terms and conditions and give my consent to pay the fees eligible for this program.
                                    </a>
                                    <span className="text-red-600 font-medium">*</span>
                                </div>
                            </div>
                            <div className="mb-2">
                                <button
                                    onClick={handleEnrollment}
                                    className="bg-blue-600 text-white py-2 px-6 rounded-full transition duration-300 transform hover:bg-white hover:text-blue-600 hover:scale-105 shadow-lg">
                                    Enroll now for the course and <br /> proceed with payment
                                </button>
                            </div>
                        </div>
                        {/* <div className="md:w-[82%] m-2 p-3 rounded-xl border-2 border-gray-700">
                            <h3 className="text-2xl font-bold">COURSE DETAILS </h3>
                            <ul className="list-disc text-gray-700 font-medium ml-5 space-y-1">
                                <li>35 + interactive lectures (2 hours) </li>
                                <li>Mode: Online (5 months program)</li>
                                <li>Weekly Lectures (Thurs & Sun)</li>
                                <li>Fee: 37,000/- (installments aval)</li>
                                <li>Program accessibility for 1 year</li>
                                <li>Study material</li>
                                <li>Program orientation & website access on  28/09/2024</li>
                                <li>Lectures starting from  29/09/2024</li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Course;